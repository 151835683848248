import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import LazyLoadSection from "../components/LazyLoadSection";
import HomeStatic from "../components/sections/HomeStatic";
import loadable from "@loadable/component";

const AsyncSection = loadable(() => import("../components/sections/HomeDefer"));

const IndexPage = () => (
  <Layout>
    <Seo
      title="StackCommerce"
      titleTemplate="Commerce Platform for Publishers & Brands"
      description="StackCommerce’s award-winning commerce solutions help users to discover and purchase products on the publisher sites they visit daily."
    />

    <LazyLoadSection static={<HomeStatic />}>
      <AsyncSection />
    </LazyLoadSection>
  </Layout>
);

export default IndexPage;
