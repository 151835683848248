import React from "react";
import styled from "styled-components";
import { gray4, white } from "./styles/Colors";
import { mediaMedium, mediaTablet, mediaSmall } from "./styles/variables";
import Container from "./styles/Container";
import MainHeroTitle from "./MainHeroTitle";
import { Link } from "gatsby";
import PagePadding from "./styles/PagePadding";
import HomeLandingAnimation from "./HomeLandingAnimation";

const Wrapper = styled.div`
  margin-top: -104px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  max-width: 100vw;
`;

const Top = styled.div`
  padding: 171px 0 144px;
  background-color: ${gray4};

  @media (${mediaTablet}) {
    padding: 172px 0 160px;
  }

  @media (${mediaSmall}) {
    padding-bottom: 160px;
  }
`;

const Bottom = styled.div`
  background-color: ${white};
  padding-bottom: 124px;
  position: relative;

  @media (${mediaTablet}) {
    position: static;
    padding-bottom: 140px;
  }

  @media (${mediaMedium}) {
    padding-bottom: 236px;
  }

  @media (${mediaSmall}) {
    padding-bottom: 140px;
  }
`;

const IndexLanding = () => {
  return (
    <Wrapper>
      <Top>
        <Container style={{ padding: "0", position: "relative" }}>
          <PagePadding>
            <MainHeroTitle
              label="Get Started"
              link={{ to: "/contact/", state: { from: "publisher" }, as: Link }}
            >
              Where content meets commerce
            </MainHeroTitle>
          </PagePadding>
          <HomeLandingAnimation />
        </Container>
      </Top>
      <Bottom />
    </Wrapper>
  );
};

export default IndexLanding;
