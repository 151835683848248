import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "./Image";
import styled, { css } from "styled-components";
import { FadeSlideInTopRight } from "./styles/animations";
import { mediaMedium } from "./styles/variables";

const Container = styled.div`
  pointer-events: none;
  position: absolute;
  width: 40%;
  right: 20%;
  top: 210px;

  @media (min-width: 375px) {
    top: 200px;
  }

  @media (min-width: 414px) {
    top: 130px;
  }

  @media (min-width: 500px) {
    width: 30.5%;
    right: 4%;
    top: 25%;
  }

  @media (min-width: 640px) {
    width: 30.5%;
    right: 4%;
    top: -20.2%;
  }

  @media (${mediaMedium}) {
    width: 42.5%;
    right: -1%;
    top: -20.2%;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Top = styled.div`
  position: absolute;
  width: 106%;
  top: -4px;
  left: -8px;
  z-index: 2;
`;

const Bottom = styled.div`
  position: absolute;
  width: 106%;
  left: 0;
  top: 0;
  transform: translate(-49%, 69%);
  z-index: 3;
`;

const Right = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  transform: translate(32%, 49%);
  z-index: 4;
`;

const Animation = styled(Image)`
  transform: translateY(-20%) translateX(20%);
  opacity: ${(props) => (props.active ? 1 : 0)};
  animation: ${(props) =>
    props.active
      ? css`
          ${FadeSlideInTopRight} .55s linear forwards
        `
      : "none"};

  animation-delay: ${(props) => props.delay ?? 1}s;
  transition: opacity 0.6s linear;
  transition-delay: ${(props) => props.delay ?? 1}s;
`;

const HomeLandingAnimation = () => {
  const images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "7-hero-landing-2.png" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      two: file(relativePath: { eq: "7-hero-landing-1.png" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      three: file(relativePath: { eq: "7-hero-landing-3.png" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const [active, setActive] = useState(false);
  const handleLoad = () => {
    setActive(true);
  };

  return (
    <>
      <Container>
        <Wrapper>
          <Top>
            <Animation
              onLoad={handleLoad}
              loading="eager"
              fluid={images.one.childImageSharp.fluid}
              active={active}
              delay={0.4}
            />
          </Top>

          <Bottom>
            <Animation
              onLoad={handleLoad}
              loading="eager"
              fluid={images.two.childImageSharp.fluid}
              active={active}
              delay={0.3}
            />
          </Bottom>

          <Right>
            <Animation
              onLoad={handleLoad}
              loading="eager"
              fluid={images.three.childImageSharp.fluid}
              active={active}
              delay={0.6}
            />
          </Right>
        </Wrapper>
      </Container>
    </>
  );
};

export default HomeLandingAnimation;
