import React from "react";
import IndexLanding from "../IndexLanding";
import TitleOutline from "../HeroOutlineTitle";
import HeroDivideRight from "../HeroDivideRight";
import TextHighlight from "../styles/TextHighlight";
import { white } from "../styles/Colors";

const HomeStatic = () => {
  return (
    <>
      <IndexLanding />

      <TitleOutline
        theme={{
          paddingTopMobile: "0",
          paddingBottom: "0",
          marginBottom: "-10px"
        }}
        title="FUELING PRODUCT"
        subtitle="DISCOVERY"
      />

      <HeroDivideRight
        staticLines={true}
        title="Who We Are"
        links={[
          { label: "For Publishers", args: { to: "/publishers/" } },
          { label: "For Brands", args: { to: "/brands/" } }
        ]}
      >
        Stack is a product discovery platform. We partner with{" "}
        <TextHighlight theme={{ background: white }}>
          1,000 + publishers
        </TextHighlight>{" "}
        and{" "}
        <TextHighlight theme={{ background: white }}>
          5,000+ brands
        </TextHighlight>{" "}
        to help real people discover products and brands they love every day.
      </HeroDivideRight>
    </>
  );
};

export default HomeStatic;
